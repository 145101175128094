$cp-blue: #006e96;
$cp-orange: #FF6F00;

$loading-placeholder-color: #aaa;

$warning-color: #F0D24B;
$error-color: #EB193C;
$error-color-light: #F7DCE0;
$error-color-hover: #F42C4C;
$success-color: #00EB69;

$border-line-color-light: #ddd;
$border-line-color-dark: #7F7F7F;
$hint-color: #7F7F7F;

$disabled-color: #DBDBDB;
$disabled-color-dark: #7F7F7F;
$unfilled-grey-circle-color: #F2F2F2;
$unfilled-blue-circle-color: #D1DDEB;

$background-login-send-button: #FFFFFF;
$background-white-opacity: rgba(255,255,255,0.8);  // ensure table data can be raed in front of cp icon at the bottom

$mouse-pressed-button-color: #005B7C;
$active-input-color: #006E96;
$border-inactive-search-field-color: #006E96;
$mouse-over-buttons-color: #197DA0;
$mouse-over-navigation-color: #197DA0;

$week-background: rgba($cp-orange, 0.3);
$highlight-circle: #D1DDEB;
$color_petrol: #006E96;

$hover-item-list: #eef4fb;
$active-item-list: #d1ddeb;

$archived-item-background-color: rgba(black, 0.1);
$icon-button-focused: rgba(black, 0.05);

// Font definitions
$font_regular: 400;
$font_semi-bold: 600;
$font_14: 0.875rem;
$font_16: 1rem;
$font_20: 1.25rem;
$font_24: 1.5rem;
$font_color_active: $cp-blue;
$font_color_hint: $hint-color;
$font_color_default: #171717;
//$font_color_default: #1e1e1e;
$font_color_light: white;
$font_color_link: #000aff;

$font_family_default: 'Open Sans', sans-serif;

/* default font definition! */
@mixin default-font() {
  font-weight: $font_regular;
  font-size: $font_16;
  color: $font_color_default;
  font-family: $font_family_default;
}

/*************** customized Scrollbars *************/
::-webkit-scrollbar {
  background-color: #f2f2f2;
  border-radius: 3px;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 3px;
  width: 6px;
  height: 6px;
}

.underline-dark {
  border-bottom: 1px solid $border-line-color-dark;
}

.white-stroked.mat-progress-spinner circle, .white-stroked.mat-progress-spinner circle {
  stroke: white;
}

.orange-stroked.mat-progress-spinner circle, .white-stroked.mat-progress-spinner circle {
  stroke: $cp-orange;
}

.export-text-container {
  display: flex;
  align-items: center;
  margin: auto;
}

// Define primary color for mat-buttons
.mat-mdc-icon-button,
.mat-mdc-button,
.mdc-button__label,
mat-mdc-select .mat-icon,
mat-mdc-option .mat-icon{
  &:not(.mdc-button__label){
    color: $cp-blue;

  }
  user-select: none;
  letter-spacing: normal;
}

.mat-mdc-icon-button[disabled='true'] {
  color: $disabled-color-dark;
}

.mat-mdc-button-persistent-ripple {
  display: none;
  cursor: pointer;
}

.mat-icon-button:active:focus {
  color: $cp-orange;
  font-weight: $font_semi-bold;
}



.mat-mdc-icon-button .mat-mdc-button-ripple .mat-mdc-ripple-element {
  background-color: rgba($cp-blue, .1);
}

//Define warning color for mat-buttons
.mat-icon-button.mat-warn {
  color: $warning-color;
}

.mat-mdc-icon-button.mat-mdc-warn .mat-mdc-button-ripple .mat-mdc-ripple-element {
  background-color: rgba($warning-color, .1);
}


//Define error color for mat-buttons (need .critical class)
.mat-icon-button.mat-warn.critical {
  color: $error-color;
}

.mat-mdc-icon-button.mat-mdc-warn.critical .mat-mdc-button-ripple .mat-mdc-ripple-element {
  background-color: rgba($error-color, .1);
}


//Define disable color for mat-buttons (need .disabled class)
.mat-icon-button.disabled {
  pointer-events: none;
  color: $disabled-color;
}

.mat-unelevated-button.disabled {
  background-color: $disabled-color;
}

//Define default action-button style
.action-button {
  padding: 5px 10px;
  border: 1px solid $cp-blue;
  border-radius: 3px;
  color: $cp-blue;
  background-color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: $font_16;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
}

button[mat-stroked-button] {
  border: 1px solid $cp-blue !important;
}

.select-options-container{
  border: 1px solid $cp_blue;
  border-radius: 3px !important;
  background-color: white !important;
}


//Define global absence background color
* {
  &.absence-day-light {
    background-color: #eef4fb;
  }

  &.absence-day-dark {
    background-color: #d1ddeb;
  }

  &.absence-day-calendar {
    background-color: #d1ddeb;
  }
  &.absence-day-submitted {
    background-color: #7f7f7f;
  }
  &.absence-day-calendar-submitted {
    background-color: #f2f2f2;
  }
  &.absence-day-submitted-dark {
    background-color: #dbdbdb;
  }
  &.absence-day-submitted-light {
    background-color: #f2f2f2;
  }

  &.absence-day-calendar-not-in-month {
    background-color: #f2f2f2;
  }

}

.no-scroll {
  overflow: hidden;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}

.mdc-fab {
  &:not([disabled]){
  color: $cp-blue;
    }
  background-color: transparent !important;
  box-shadow: none !important;

  .mat-mdc-button-touch-target{
    width: 40px !important;
    height: 40px !important;
  }
  &:hover,
  &:focus-visible {
    .mat-mdc-button-touch-target{
      border-radius: 50%;
      background-color: $icon-button-focused;
    }
  }
}


